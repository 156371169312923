
.loginPage {

    width: 100%; 
    height: 100%;
    background-color: #e4e4e7;
    display: flex; 
    flex-direction: column; 

    .pageContent {

        display: flex;
        flex-direction: row;
        height: 100%;

        .left {
            flex: 6;
            background-image: url(./../../../../assets/images/login-bg.svg);
            background-repeat: no-repeat;
            background-position: 100%;
            background-size: cover;
        }

        .right{
            flex: 4;
            display: flex;
            flex-direction: column;
            height: 100%;

            .rightRow1 {
                flex: 1;
            }

            .rightRow2 {

            }

            .rightRow3 {
                flex: 2;
            }
        }
    }

    .pageFooter {
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: rgb(128,128,128);
    }

    
}
