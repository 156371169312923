
.EmployeeDetail {

    .tabs {
        width: 100%;
        height: 100%;

        .ant-tabs-content-holder{
            width: 100%;
            height: 100%;

            .ant-tabs-content{
                width: 100%;
                height: 100%;
            }
        }
    }
}
