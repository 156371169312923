


.dashboard {

    .frequencyButton {
        display: inline-block; 
        width: 72px; 
        height: 72px; 
        text-align:center; 
        border: 1px solid #2791fe; 
        border-radius:8px; 
        padding-top:14px; 
        margin-right:16px; 
        margin-bottom: 16px;
        cursor:pointer;
    }
}
