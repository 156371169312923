
.HomePage {
    width: 100%;
    height: 100%;

    .ant-layout-content{
        padding: 0px;
        margin: 0px;
        background-color: #fff;
    }

    .ant-tabs-nav {
        margin-bottom: 0px;

        .ant-tabs-nav-wrap{
            padding-left: 8px;
            padding-right: 8px;
            padding-bottom: 2px;

            .ant-tabs-tab {
                padding-left: 12px;
                padding-right: 12px;
            }
        }
    }

    .HomeHeader {
        height: 48px;
        background-color: rgb(39,145,254);
        color: #fff;
        padding: 0px;
        line-height: 48px;

        .LogoTitle {
            font-size: 18px;
            font-weight: bold;
            display: inline-block;
            margin-left: 16px;
        }
    }

    .HomeContent{
        width: 100%;
        height: 100%;

        .ant-tabs-content-holder{
            width: 100%;
            height: 100%;
      
            .ant-tabs-content {
              width: 100%;
              height: 100%;
            }
        }
    }

    .HomeContentTabBody {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: rgb(240, 242, 245);
        .TabPaneBody {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: auto;
            padding: 16px;
        }
    }
}
